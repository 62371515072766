/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Main from './common/main';
import Footer from './footer';

import variables from '../utils/variables';
// import '../utils/grid';


// import './layout.css'
import '../layout/_layout.scss'

const styles = {
  pageWrapper: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: variables.colors.white,
  }
}

const Layout = ({ headerDark, currentPage, children, updateBreakpoint, data, contentPage }) => {

  const [currentBreakPoint, setCurrentBreakPoint] = useState({
    type: null,
    bp: null,
  });
  
  const [openedBurgerMenu, setOpenedBurgerMenu] = useState(false);

  

  const toggleBurgerMenu = () => {
    setOpenedBurgerMenu(!openedBurgerMenu);
  }
  
  if (typeof window !== `undefined`) {
    let processing = false;
    const updateSize = () => {
      const w = typeof window !== 'undefined' ? window.innerWidth : 0;
      let breakpoint = null;
      let type = null;

      if (w >= variables.breakpoints['xxxl']) {
        breakpoint = 'xxxl';
        type = 'desktop';
      } else if (w >= variables.breakpoints['xxl']) {
        breakpoint = 'xxl';
        type = 'desktop';
      } else if (w >= variables.breakpoints['xl']) {
        breakpoint = 'xl';
        type = 'desktop';
      } else if (w >= variables.breakpoints['l']) {
        breakpoint = 'l';
        type = 'mobile';
      } else if (w >= variables.breakpoints['m']) {
        breakpoint = 'm';
        type = 'mobile';
      } else if (w >= variables.breakpoints['s']) {
        breakpoint = 's';
        type = 'mobile';
      } else {
        breakpoint = 'xs';
        type = 'mobile';
      }

      if(currentBreakPoint.type !== type || currentBreakPoint.bp !== breakpoint) {
        setCurrentBreakPoint({
          bp: breakpoint,
          type,
        });

        if(updateBreakpoint) {
          updateBreakpoint({
            bp: breakpoint,
            type,
          });
        }

        setOpenedBurgerMenu(false);
        setTimeout(() => {
          processing = false;
        }, 100);
        
      }
    }

    const handleResize = () => {
      if(processing === false) {
        processing = true;
        updateSize()
      }
    };
  
    window.addEventListener('resize', handleResize);
    handleResize();

    
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
          <div
            style={{
              ...styles.pageWrapper,
              height: (openedBurgerMenu) ? '100vh' : 'auto',
            }}
          >
          <Header
            dark={headerDark}
            siteTitle={data.site.siteMetadata.title}
            currentPage={currentPage}
            openedMenu={openedBurgerMenu}
            toggleMenu={toggleBurgerMenu}
            currentBreakPoint={currentBreakPoint.type}
            contentPage={contentPage} 
          />
          <Main children={children} contentPage={contentPage} />
          <Footer contentPage={contentPage} />
        </div>
      )}
    />
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
