import React from 'react';

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

class Navigation extends React.Component{
  constructor(props) {
    super(props);

    if(!this.props.contentPage) {
      this.nav = React.createRef()
      this.line = React.createRef()

      
      this.onHover = this.onHover.bind(this);
      this.onHoverEnd = this.onHoverEnd.bind(this);
    }

  }
  componentDidMount() {
    if(!this.props.contentPage) {
      gsap.set(this.line.current, {scaleX:0, force3D: true})
      this.links = this.nav.current.querySelectorAll(`a:not(.btn)`)
      this.link = this.links[0]
      this.targets = document.querySelectorAll('[nav]');
      gsap.set(this.line.current, {x:this.links[0].getBoundingClientRect().x, force3D: true})
      const options = {
        threshold: .3
      }
      gsap.set(this.line.current, {scaleX:1,x:this.link.getBoundingClientRect().x + 15 , width:this.link.getBoundingClientRect().width - 30, force3D: true})

      this.handleObserver(options);

      window.addEventListener('resize', this.handleResize.bind(this));
      this.handleResize();
    }
  }
  handleResize() {
    if(!this.link) this.link = this.links[0]
    gsap.set(this.line.current, {scaleX:1,x:this.link.getBoundingClientRect().x + 15 , width:this.link.getBoundingClientRect().width - 30, force3D: true})
  }
  handleObserver(options) {
    const observeSection = target => {

      const io = new IntersectionObserver((entries, observer) => {
  
        entries.forEach(entry => {

          let entryTargetId = entry.target.id;

          if(entry.target.id === 'survey-1' || entry.target.id === 'survey-2') {
            entryTargetId = 'survey';
          }

          if (entry.isIntersecting) { 
            this.link = this.nav.current.querySelector(`[href="#${entryTargetId}"]`)
            if(this.link){
              this.line.current.classList.remove('inactive');
              this.links.forEach( l => l === this.link ? l.classList.add('active') : l.classList.remove('active') )
              gsap.to(this.line.current, .3, {scaleX:1,x:this.link.getBoundingClientRect().x + 15 , width:this.link.getBoundingClientRect().width - 30, force3D: true})
            } else {
              this.line.current.classList.add('inactive');
              this.links.forEach( l => l === this.link ? l.classList.add('active') : l.classList.remove('active') )
            }
          }
        })
      }, options)
      io.POLL_INTERVAL = 100;
      io.observe(target)
    }
    this.targets.forEach(observeSection)
  }

  onHover(evt) {
    this.line.current.classList.add('hover');
      gsap.to(this.line.current, .3, { 
        scaleX:1,
        x:evt.target.getBoundingClientRect().x + 15, 
        width:evt.target.getBoundingClientRect().width - 30, 
        force3D: true
      })
  }
  
  onHoverEnd() {
    this.line.current.classList.remove('hover');
    if(this.link){
      gsap.to(this.line.current, .3, {
        scaleX:1, 
        x:this.link.getBoundingClientRect().x + 15, 
        width:this.link.getBoundingClientRect().width - 30, 
        force3D: true
      })
    }
  }

  onClick(evt) {
    evt.preventDefault();
    const attr = evt.target.getAttribute('href');
    if(attr) gsap.to(window, {duration: .4, scrollTo: {y: attr, offsetY: 100}});

    this.props.onClick();
  }

  render() {
    return (
    <nav ref={this.nav} className="topbar__navigation">
      <ul>
        {(!this.props.contentPage) ? (
          <li><a onClick={this.onClick.bind(this)} onMouseOver={this.onHover} onMouseLeave={this.onHoverEnd} onFocus={this.onHover} href="#about">Pepio, c'est quoi ?</a></li>
        ) : null}
        {(!this.props.contentPage) ? (
          <li><a onClick={this.onClick.bind(this)} onMouseOver={this.onHover} onMouseLeave={this.onHoverEnd} onFocus={this.onHover} href="#survey">Créer des enquêtes</a></li>
        ) : null}
        {(!this.props.contentPage) ? (
          <li><a onClick={this.onClick.bind(this)} onMouseOver={this.onHover} onMouseLeave={this.onHoverEnd} onFocus={this.onHover} href="#pricing">Prix</a></li>
        ) : null}
        {/* <li><a onClick={this.onClick.bind(this)} onMouseOver={this.onHover} onMouseLeave={this.onHoverEnd} onFocus={this.onHover} href="#tuto">Tutoriels</a></li> */}
        
        <li><a className="btn btn--primary btn-contact" href="mailto:hello@pepio.io">Contactez-nous</a></li>
      </ul>
      <div ref={this.line} className="active-line inactive"></div>
    </nav>
    )
  }
}

export default Navigation