import React from 'react';

const Brand = () => {
  return (
    <a className="topbar__brand" href="/">
     <svg width="139" height="63" viewBox="0 0 139 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M69.0453 18.8149H62.0435C61.8894 18.8162 61.737 18.848 61.5952 18.9086C61.4534 18.9693 61.325 19.0575 61.2173 19.1682C61.1097 19.2789 61.0249 19.4099 60.9679 19.5536C60.911 19.6973 60.8829 19.851 60.8854 20.0056V38.115C60.8854 38.4809 61.0302 38.8318 61.288 39.0906C61.5458 39.3493 61.8955 39.4947 62.26 39.4947C62.6246 39.4947 62.9743 39.3493 63.2321 39.0906C63.4898 38.8318 63.6347 38.4809 63.6347 38.115V31.6606H69.0453C73.3889 31.6606 75.1276 28.4917 75.1276 25.2378C75.1307 22.05 73.3921 18.8149 69.0453 18.8149ZM68.8727 29.0745H63.6629V21.4011H69.0202C71.396 21.4011 72.3783 23.3793 72.3783 25.2378C72.3783 27.1561 71.3646 29.0745 68.8759 29.0745H68.8727Z" fill="#060A4A"/>
        <path d="M83.0336 25.1495C79.0886 25.1495 75.9502 27.9404 75.9502 32.3567C75.9502 36.773 79.1075 39.6521 83.043 39.6521C85.3027 39.6521 87.3866 38.8079 88.7173 37.0943C88.9078 36.8397 88.9906 36.52 88.9477 36.2045C88.9048 35.889 88.7397 35.6032 88.4882 35.4091C87.9672 35.0311 87.1826 35.2043 86.6616 35.7587C85.7201 36.8045 84.7786 37.2109 83.1309 37.2109C80.7865 37.2109 78.9599 35.7587 78.643 33.4309H88.8648C89.0056 33.4309 89.1451 33.403 89.2751 33.3488C89.4051 33.2946 89.5233 33.2152 89.6227 33.1152C89.7221 33.0151 89.8009 32.8963 89.8545 32.7656C89.9081 32.6349 89.9354 32.4949 89.935 32.3536C89.9256 27.9404 87.0006 25.1495 83.0336 25.1495ZM78.6398 31.3109C78.9537 28.9862 80.752 27.5908 83.0336 27.5908C85.265 27.5908 86.9127 28.9862 87.2611 31.3109H78.6398Z" fill="#060A4A"/>
        <path d="M99.9715 25.1495C98.0319 25.1495 96.0641 26.0504 95.1352 27.6191V26.6741C95.1352 26.3157 94.9933 25.972 94.7408 25.7186C94.4883 25.4652 94.1459 25.3228 93.7888 25.3228C93.4317 25.3228 93.0892 25.4652 92.8367 25.7186C92.5842 25.972 92.4424 26.3157 92.4424 26.6741V44.8654C92.4424 45.2238 92.5842 45.5675 92.8367 45.8209C93.0892 46.0744 93.4317 46.2167 93.7888 46.2167C94.1459 46.2167 94.4883 46.0744 94.7408 45.8209C94.9933 45.5675 95.1352 45.2238 95.1352 44.8654V37.2676C96.0767 38.8678 98.0602 39.6521 99.9715 39.6521C103.766 39.6521 106.92 36.8612 106.92 32.4449C106.92 28.0286 103.794 25.1495 99.9715 25.1495ZM99.711 37.0061C97.4827 37.0061 95.1665 35.4658 95.1665 32.4733C95.1665 29.4808 97.2913 27.7955 99.711 27.7955C102.2 27.7955 104.199 29.7139 104.199 32.4733C104.199 35.2327 102.2 37.0061 99.711 37.0061Z" fill="#060A4A"/>
        <path d="M110.134 22.1004C110.479 22.0782 110.809 21.9555 111.085 21.7472C111.361 21.5389 111.57 21.2541 111.687 20.928C111.804 20.6019 111.823 20.2486 111.742 19.9117C111.661 19.5748 111.484 19.2689 111.232 19.0318C110.98 18.7947 110.665 18.6367 110.325 18.5772C109.985 18.5177 109.635 18.5593 109.318 18.6969C109.001 18.8346 108.731 19.0622 108.542 19.3518C108.352 19.6414 108.251 19.9804 108.251 20.3269C108.255 20.5685 108.308 20.8067 108.406 21.0275C108.503 21.2484 108.644 21.4473 108.819 21.6127C108.995 21.778 109.202 21.9063 109.428 21.9901C109.653 22.0738 109.894 22.1113 110.134 22.1004Z" fill="#060A4A"/>
        <path d="M122.207 25.1495C118.272 25.1495 114.97 27.9404 114.97 32.3567C114.967 32.9231 115.025 33.4881 115.143 34.042C114.91 34.3349 114.681 34.672 114.421 35.0311L114.333 35.1193C113.61 35.9563 112.604 36.4941 111.508 36.6281V26.6615C111.508 26.3031 111.366 25.9594 111.114 25.706C110.861 25.4526 110.519 25.3102 110.162 25.3102C109.805 25.3102 109.462 25.4526 109.21 25.706C108.957 25.9594 108.815 26.3031 108.815 26.6615V38.1401C108.814 38.4978 108.952 38.8418 109.201 39.0976C109.45 39.3535 109.79 39.5006 110.146 39.5072C110.206 39.5122 110.267 39.5014 110.322 39.4757V39.5072C112.55 39.1922 114.662 38.3134 115.996 36.5998C116.05 36.5471 116.098 36.489 116.141 36.4265C116.804 37.4391 117.713 38.2662 118.781 38.8303C119.85 39.3945 121.044 39.6772 122.251 39.6521C126.187 39.6521 129.517 36.7447 129.517 32.3567C129.517 27.9688 126.165 25.1495 122.207 25.1495ZM122.207 37.0376C119.718 37.0376 117.634 35.176 117.634 32.3567C117.634 29.5375 119.718 27.7955 122.207 27.7955C124.696 27.7955 126.792 29.569 126.792 32.3567C126.792 35.1445 124.699 37.0376 122.207 37.0376Z" fill="#060A4A"/>
        <path d="M33.1672 21.7413L37.6614 19.7915L30.2264 9.78076L29.0903 15.1799L30.2264 15.0822L33.1672 21.7413Z" fill="#80E9DC"/>
        <path d="M39.9556 28.0349L41.9704 28.6208L40.9473 26.0063L39.9556 28.0349Z" fill="#80E9DC"/>
        <path d="M16.3511 35.6486L14.9702 47.1083L26.6671 44.1662L22.6437 39.0128L16.3511 35.6486Z" fill="#80E9DC"/>
        <path d="M39.2524 33.4466L40.7777 36.7132L51.8658 31.4999L41.9703 28.6208L43.097 31.4999L39.2524 33.4466Z" fill="#B9AAEC"/>
        <path d="M27.4767 22.8406L29.0899 15.1798L14.6562 16.4178L17.666 24.885L21.1246 22.365L27.4767 22.8406Z" fill="#B9AAEC"/>
        <path d="M27.9697 45.8357L30.2263 53.2193L32.5299 48.2832L30.0882 48.5478L27.9697 45.8357Z" fill="#B9AAEC"/>
        <path opacity="0.5" d="M27.4771 22.8406L30.1761 23.0422L33.167 21.7413L30.2263 15.0822L29.0902 15.1798L27.4771 22.8406Z" fill="#00D3B9"/>
        <path d="M27.4771 22.8406L30.1761 23.0422L33.167 21.7413L30.2263 15.0822L29.0902 15.1798L27.4771 22.8406Z" fill="#7A9FDB"/>
        <path opacity="0.5" d="M38.3047 31.4181L39.2525 33.4467L43.0971 31.5L41.9704 28.6209L39.9555 28.035L38.3047 31.4181Z" fill="#00D3B9"/>
        <path d="M38.3047 31.4181L39.2525 33.4467L43.0971 31.5L41.9704 28.6209L39.9555 28.035L38.3047 31.4181Z" fill="#7A9FDB"/>
        <path d="M40.7776 36.7133L37.1276 38.43L32.5298 48.2832L45.5229 46.8626L40.7776 36.7133Z" fill="#8FBFF9"/>
        <path d="M19.6279 30.4038L17.6664 24.885L8.58691 31.5L16.3514 35.6486L16.8535 31.5L19.6279 30.4038Z" fill="#8FBFF9"/>
        <path d="M26.6675 44.1661L27.9699 45.8356L27.4019 43.9834L26.6675 44.1661Z" fill="#8FBFF9"/>
        <path d="M40.9471 26.0064L45.6861 16.3044L37.6611 19.7915L39.4689 22.2233L40.9471 26.0064Z" fill="#8FBFF9"/>
        <path opacity="0.5" d="M27.4015 43.9835L25.123 36.5243L19.9885 31.4181L19.6276 30.4038L16.8532 31.5L16.3511 35.6486L22.6436 39.0128L26.6671 44.1662L27.4015 43.9835Z" fill="#00D3B9"/>
        <path d="M27.4015 43.9835L25.123 36.5243L19.9885 31.4181L19.6276 30.4038L16.8532 31.5L16.3511 35.6486L22.6436 39.0128L26.6671 44.1662L27.4015 43.9835Z" fill="#50B5E7"/>
        <path opacity="0.5" d="M35.3576 26.6963L39.9554 28.0351L40.9472 26.0065L39.469 22.2233L37.6612 19.7915L33.167 21.7414L35.3576 26.6963Z" fill="#00D3B9"/>
        <path d="M35.3576 26.6963L39.9554 28.0351L40.9472 26.0065L39.469 22.2233L37.6612 19.7915L33.167 21.7414L35.3576 26.6963Z" fill="#50B5E7"/>
        <path opacity="0.5" d="M40.7778 36.7132L39.2526 33.4467L34.5418 35.8344L30.2264 43.2715L27.4019 43.9834L27.9699 45.8356L30.0884 48.5477L32.5301 48.2832L37.1279 38.43L40.7778 36.7132Z" fill="#7455DA"/>
        <path d="M40.7778 36.7132L39.2526 33.4467L34.5418 35.8344L30.2264 43.2715L27.4019 43.9834L27.9699 45.8356L30.0884 48.5477L32.5301 48.2832L37.1279 38.43L40.7778 36.7132Z" fill="#6C95EF"/>
        <path opacity="0.5" d="M26.4505 27.7105L27.4767 22.8406L21.1246 22.365L17.666 24.885L19.6275 30.4038L26.4505 27.7105Z" fill="#7455DA"/>
        <path d="M26.4505 27.7105L27.4767 22.8406L21.1246 22.365L17.666 24.885L19.6275 30.4038L26.4505 27.7105Z" fill="#6C95EF"/>
        <path opacity="0.5" d="M26.4509 27.7106L19.6279 30.4038L19.9888 31.4181L19.6279 30.4038L26.4509 27.7106Z" fill="#00D3B9"/>
        <path opacity="0.5" d="M26.4509 27.7106L19.6279 30.4038L19.9888 31.4181L19.6279 30.4038L26.4509 27.7106Z" fill="#7455DA"/>
        <path opacity="0.5" d="M26.4509 27.7106L19.6279 30.4038L19.9888 31.4181L19.6279 30.4038L26.4509 27.7106Z" fill="#1F80F3"/>
        <path opacity="0.5" d="M39.2525 33.4467L38.3047 31.4181L39.2525 33.4467Z" fill="#00D3B9"/>
        <path opacity="0.5" d="M39.2525 33.4467L38.3047 31.4181L39.2525 33.4467Z" fill="#7455DA"/>
        <path opacity="0.5" d="M39.2525 33.4467L38.3047 31.4181L39.2525 33.4467Z" fill="#1F80F3"/>
        <path opacity="0.5" d="M27.477 22.8407L26.4507 27.7106L27.477 22.8407Z" fill="#00D3B9"/>
        <path opacity="0.5" d="M27.477 22.8407L26.4507 27.7106L27.477 22.8407Z" fill="#7455DA"/>
        <path opacity="0.5" d="M27.477 22.8407L26.4507 27.7106L27.477 22.8407Z" fill="#1F80F3"/>
        <path opacity="0.5" d="M33.167 21.7413L35.3576 26.6963L33.167 21.7413Z" fill="#00D3B9"/>
        <path opacity="0.5" d="M33.167 21.7413L35.3576 26.6963L33.167 21.7413Z" fill="#7455DA"/>
        <path opacity="0.5" d="M33.167 21.7413L35.3576 26.6963L33.167 21.7413Z" fill="#1F80F3"/>
        <path opacity="0.5" d="M27.4019 43.9834L30.2264 43.2715L27.4019 43.9834Z" fill="#00D3B9"/>
        <path opacity="0.5" d="M27.4019 43.9834L30.2264 43.2715L27.4019 43.9834Z" fill="#7455DA"/>
        <path opacity="0.5" d="M27.4019 43.9834L30.2264 43.2715L27.4019 43.9834Z" fill="#1F80F3"/>
        <path d="M27.4771 22.8407L30.1761 23.0423L27.4771 22.8407Z" fill="#060A4A"/>
        <path d="M33.1667 21.7413L30.1758 23.0423L33.1667 21.7413Z" fill="#060A4A"/>
        <path d="M38.3047 31.4181L39.9555 28.035L38.3047 31.4181Z" fill="#060A4A"/>
        <path d="M27.4015 43.9835L25.123 36.5243L27.4015 43.9835Z" fill="#060A4A"/>
        <path d="M39.9552 28.035L35.3574 26.6963L39.9552 28.035Z" fill="#060A4A"/>
        <path d="M30.2261 43.2715L34.5414 35.8344L39.2522 33.4467L34.5414 35.8344L30.2261 43.2715Z" fill="#060A4A"/>
        <path d="M39.9555 28.035L35.3577 26.6963L33.1671 21.7413L30.1762 23.0423L27.4771 22.8407L26.4509 27.7106L19.6279 30.4038L19.9888 31.4181L25.1233 36.5243L27.4018 43.9835L30.2264 43.2716L34.5417 35.8344L39.2525 33.4467L38.3047 31.4181L39.9555 28.035Z" fill="#060A4A"/>
      </svg>
    </a>
  )
}

export default Brand