const variables = {
  breakpoints: {
    's': 690,
    'm': 960,
    'l': 1024,
    'xl': 1180,
    'xxl': 1440,
    'xxxl': 1600,
    'max': 2000,
  },
  colors: {
    white: '#fff',
    black: '#000',
    greyDarker: '#585967',
    greyDark: '#7E7E8D',
    grey: '#A3A4B2',
    greyLight: '#C9C9D8',
    greyLighter: '#EEEFFD',
    shadow: '#7474BC',
    backgroundLight: '#E5E5E5',
    backgroundDark: '#333342',
    backgroundExtraLight: '#FAFAFE',
    blue: '#4E56FF',
    red: '#FF6562',
    green: '#54E9DC',
    twitter: '#1da1f2',
    facebook: '#1877f2',
    medium: '#02b875',
  }
};

export const mq = (breakpoint, min) => `@media screen and (${(min === true) ? 'min' : 'max'}-width: ${variables.breakpoints[breakpoint]}px)`;

export const isTouch = () => {  
  try {  
      document.createEvent("TouchEvent");  
      return true;  
  } catch (e) {  
      return false;  
  }  
}

export const isTouchMedia = (hover = 'hover') => `@media (hover: ${hover})`;

export const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min +1)) + min;
}
export default variables;