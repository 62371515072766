import React from 'react';

// Components
// import Social from './social.js'
import Partners from './partners.js'
// import Newsletter from './newsletter.js'
// import Logo from './logo.js'


const Footer = ({contentPage}) => (
  <footer className={`bottombar ${(contentPage ? 'content-page' : '')}`}>

    {/* Column : 1 */}
    {/* <section className="bottombar__column bottombar--left">
      
      <h2>Sonder l'opinion n'a jamais été aussi simple</h2>

      <a href="/" className="btn btn--secondary">Participer à un sondage</a>
      <a href="/" className="btn btn--border">Essayer Pepio Maker</a>
    </section> */}

    {/* Column : 2 */}
    {/* <section className="bottombar__column bottombar--middle">
        <ul className="socials">
          <Social />
        </ul>

        <Newsletter />
        
    </section> */}


    {/* Column : 3 */}
    {/* <section className="bottombar__column bottombar--right">
      <a href="/">
        <Logo />
      </a>
      <Newsletter />
    </section> */}

    {/* <section className="bottombar__column bottombar--newsletter">
      <Newsletter />
    </section> */}

    {/* Column : Copyright */}
    <section className="copyright">

      <div>
        <h3 className="h5">Un partenariat</h3>
        <ul className="partners">
          <Partners />
        </ul>
      </div>

      <nav>
        <ul>
          <li><a href="https://web.pepio.app/terms-and-conditions" target="_blank" >Terms and conditions</a></li>
          <li><a href="https://web.pepio.app/privacy-policy" target="_blank" >Privacy Policy</a></li>
          <li><p>Copyright © Pepio 2020</p></li>
        </ul>
      </nav>
    </section>
  </footer> 
)
export default Footer
