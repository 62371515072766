import React from 'react'

import {getRandomIntInclusive} from './../../utils/variables';
import {gsap, Power1} from 'gsap';

class Main extends React.Component{
  constructor(props){
    super(props);
    this.background = React.createRef();
  
    this.state = {
      wheel:0,
      scroll:0,
      current: null
    }
    this.nb = 6
    this.colors = ['#00D3B9', '#373B99','#C8CADA', '#7455DA', '#C8CADA'];
    this.min = 12
    this.max = 20

    this.spans = []
    this.onMouseMove = this.onMouseMove.bind(this);

  }
  
  componentDidMount(){
    if(!this.props.contentPage) {
      this.wheel = 0
      this.setBackground();

      window.addEventListener('mousemove',this.onMouseMove);
      this.onScroll()
      
    }

  }

  setBackground(){
    for (let index = 0; index <= this.nb; index++) {
      const div = document.createElement('div');
      const span = document.createElement('span');

      div.classList.add('circle')
      div.classList.add('circle__container')
      span.classList.add('circle--parts')
      
      span.wh = getRandomIntInclusive(this.min, this.max);
      const color = getRandomIntInclusive(0, this.colors.length - 1);
      if(index < 2){
        const top = getRandomIntInclusive(100, window.innerHeight - span.wh);
        const left = getRandomIntInclusive(0, 120 );
        div.style.top = `${top}px`
        div.style.left = `${left}px`
      }
      else if(index >= 2 && index < 4) {
        const top = getRandomIntInclusive(100, 200);
        const left = getRandomIntInclusive(0, window.innerWidth - span.wh );
        div.style.top = `${top}px`
        div.style.left = `${left}px`
      }
      else if(index > 4 && index < 6) {
        const top = getRandomIntInclusive(100, window.innerHeight - span.wh);
        const right = getRandomIntInclusive(0, 120) ;
        div.style.top = `${top}px`
        div.style.right = `${right}px`
      }
      else {
        const bottom = getRandomIntInclusive(90, 200);
        const left = getRandomIntInclusive(0, window.innerWidth - span.wh );
        div.style.bottom = `${bottom}px`
        div.style.left = `${left}px`
      }
      span.style.width = `${span.wh}px`
      span.style.height = `${span.wh}px`
      span.style.background = this.colors[color]
     
      div.appendChild(span)
      this.spans.push(span)
      this.background.current.appendChild(div)

      const amplitude = getRandomIntInclusive(5, 15)
      var tl = gsap.timeline({repeat:-1});
      tl.to(div,30/span.wh, {
        y: amplitude,
        ease: Power1.easeInOut
      }).to(div,30/span.wh, {
        y: 0,
        ease: Power1.easeInOut
      })
    }
    gsap.to(this.spans,.8,{x:0, y:0, force3D : true, opacity:1, delay: .3})
  }

  onMouseMove(evt) {
    const x = evt.clientX
    const y = evt.clientY
    gsap.to(this.spans,.5, {
      y: (el) => {
        return -(y/this.spans[el].wh)/2
      },
      x: (el) => {
        return -(x/this.spans[el].wh)/2
      },
      stagger: .02
    })
  }

  onScroll(){
   
    window.addEventListener('scroll', (evt) => {
      // console.log('scroll');
      const y = (window.pageYOffset || document.scrollTop)  - (document.clientTop || 0);
      this.down = y >= this.state.scroll ? true : false;
      this.setState({
        scroll:y,
        down: this.down
      })
    });
  }
  render(){
    const children = React.Children.map(this.props.children, child => (
      React.cloneElement(child, {
        state: this.state,
      })
    ))
    
    if(this.props.contentPage && typeof window !== `undefined`) {
      setTimeout(() => {
        document.querySelector('html').scrollTop = 0;
        document.querySelector('body').scrollTop = 0;
      }, 50);
    }
    
    return (
      <div>
      <main>
        {children}
      </main>
      <div className="mainBackground" ref={this.background}></div>
      </div>
    )
  }
}

export default Main