import React from 'react';

import Brand from './parts/brand'
import Navigation from './parts/navigation'
import Burger from './parts/burger'

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({ open: !this.state.open })
    document.body.style.overflow = !this.state.open ? 'hidden' : 'visible'
  }

  handleClose() {
    this.setState({ open: false })
    document.body.style.overflow = 'visible'
  }

  render() {

    return (
      <header data-open={this.state.open} className="topbar" >
        <div className="topbar__container">
          <Brand />
          <Navigation contentPage={this.props.contentPage} onClick={() => {this.handleClose()}} />
          <Burger onClick={this.handleClick} />
        </div>
      </header>
    );
  }
}

export default Header
