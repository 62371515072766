import React from 'react';

class Burger extends React.Component {
  render() {

    return (
      <button className="burger" onClick={this.props.onClick}>
        <span className="burger-line"></span>
        <span className="burger-line"></span>
        <span className="burger-line"></span>
      </button>
    )

  }
}

export default Burger;